import { YearInfo, sidebarText } from "./interfaces";
export const yearInfoMap: { [key: number]: YearInfo[] } = {
    2019: [
        {
            headerIndex: 0,
            info: "For MEA Mobile Ltd. I was tasked with working in a team of 4 with 2 staff members from MEA Mobile Ltd. to supervise us. \n" +
                "Unfortuantely due to NDA, I am not able to disclose specifics of the project. \n" +
                "The task involved us to extract information from MEA's servers and display them on a user friendly graphical user interface (GUI). \n" +
                "In order to achieve high quality results we focus on 12 functional and 2 non-functional requirements. We constructed software architecture of the interface." +
                "Once completed we were able to create a high level proposal pitch which was swiftly approved to begin the development phase. \n" +
                "During development we decided to have the front end and the back end teams where we communicated via endpoints." +
                "The front end team was in charge of creating low and high fedality paper prototypes and working with key stakeholders to determine a high quality design. This would later be developed." +
                "The back end team primarily worked with MEA Mobile to get the endpoints from their servers to extract information and pass the information to the front end team. \n" +
                "My primary contributions involved me working on the front end team, taking a proactive approach to liase with the back end team and key stakeholders to create a functional report, once completed I wrote the handover document and " +
                "reports to provide to allow MEA to use the project once the project concluded."
        },
        {
            headerIndex: 1,
            info: "<a href='https://www.maramatanga.ac.nz/' target='_blank' rel='noopener noreferrer'>Ngā Pae o Te Māramatanga</a> (NPM) is the only Māori Centre of Research Excellence (CoRE) where every summer they " +
                "intake multiple Māori academic students to complete a research topic. I was selected by " +
                "Associate Professor Te Taka Keegan to participate in assisting David Trye with his project involving a Māori Corpus from Twitter called " +
                "<a href='https://www.maramatanga.ac.nz/news-events/news/30-summer-internships-underway' target='_blank' rel='noopener noreferrer'>A Corpus of Māori Language Tweets</a>. " +
                ""
        }
    ],
    2020: [
        {
            headerIndex: 0,
            info: "I worked within a team of 6 members on the <a href='https://globalgamejam.org/' target='_blank' rel='noopener noreferrer'>Global Game Jam 2020</a>. A Global Game Jam is where teams are given 48 hours to make a game " +
            "I worked within a team of 6 where my primary involvement was in level design and assisting with asset management " +
                "within the team alongside manage the timeline and events to ensure we are able to hit our deadlines. Game can be found " +
                "<a href='https://v3.globalgamejam.org/2020/games/classified-5' target='_blank' rel='noopener noreferrer'> here</a>"
        },
        {
            headerIndex: 1,
            info: "My honours project involved me working under my supervisors Panos Patros and Te Taka Keegan with guidance from ASRC Federal members Aaron Dant and Phil Feldman where I had to liase with another honours student to compare indigenous " +
                "and general approaches through Slack user studies, examining common literature, meeting with " +
                "<a href='https://profiles.waikato.ac.nz/robert.joseph' target='_blank' rel='noopener noreferrer'>Robert Joseph</a>" +
                "to provide key whakatauki into whakawhanaungatanga (building and maintaining relationships), manaakitanga, kanohi ki te kanohi (face to face), mihi (the process of gift giving), " +
                "community based environment and a 300 year ahead viewpoint. Which help to propel a proposal for a user study if the project was to progress alongside a prototype of the platform on how to move forward."
        }
    ],
    2021: [
        {
            headerIndex: 0,
            info: "Completed Bachelor of Computing and Mathetmatical Sciences at University of Waikato with Honours."
        },
        {
            headerIndex: 1,
            info: "This is a continuation of my Honours project of Huri Whakatau where I changed from focusing on a website based design to a more general Māori Requirement Engineering approach, " +
                "I delved into multiple research project conducted in the wider tech industry and indigenous Māori space to formulate the best adapted method for Māori software. Throughout the course of the project I was given the opportunity to work with " +
                "Professor Panos Patros, Associate Professor Te Taka Keegan, Doctor Andreea Calude and various people with Māori descent to adapt and test my approach to determine my findings. A copy of my Masters research thesis can be found " +
                "<a href='https://researchcommons.waikato.ac.nz/items/8c1bff18-8137-4296-8bbd-ddbd085be9cf' target='_blank' rel='noopener noreferrer'>here</a>"
        },
        {
            headerIndex: 2,
            info: "During my time completing my masters at the University of Waikato, I was invited to the Podcast " +
                "<a href='https://open.spotify.com/show/1LbhKoVnk1jyHC6WfQH90x' target='_blank' rel='noopener noreferrer'>Māori in Engineering</a>" +
                "by Alyce Lysaght. I explained my journey growing up, in university, the Huri Whaaktau research project, and my future plans moving forward. You can find a copy of the recording " +
                "<a href='https://podcasters.spotify.com/pod/show/alyce-lysaght/episodes/14--Tamahau-Brown-e1fvkos' target='_blank' rel='noopener noreferrer'>here.</a>"
        },
        {
            headerIndex: 3,
            info: "While studying my masters, my project was submitted to Engineering New Zealand from Dr. Panos Patros outlining the importance of Huri Whakatau. My research is explained as taking a standard software elicitation methodology " +
                "and how my methodology integrates with kaupapa Māori principles. The article can be found " +
                "<a href='https://www.engineeringnz.org/news-insights/embracing-m%C4%81tauranga-and-kaupapa-m%C4%81ori/' target='_blank' rel='noopener noreferrer'> here. </a>"
        },
    ],
    2022: [
        {
            headerIndex: 0,
            info: "From my work in 2019 regarding Māori Corpus of Tweets David Trye was using the data provided for his PhD project where he created a publication to " +
            "<a href='https://link.springer.com/article/10.1007/s10579-022-09580-w' target='_blank' rel='noopener noreferrer'>Springer</a> where I was acknowledged for my contributions within the project."
        },
        {
            headerIndex: 1,
            info: "<a href='https://www.tidyinternational.com/' target='_blank' rel='noopener noreferrer'>Tidy International</a> is an enterprise resource management system (ERM) where I worked in both the Development Team and Product and Business " +
                "Operations Engineering Team (PROBE), where I worked with a .NET backend and Angular Frontend alongside figuring out how to best streamline the requirements engineering (RE) to align with the business rules, customer requirements and the " +
                "current technology stack. This involved me taking on the Tidy Clinic and Tidy 2 projects to help make the project run smoothly with the team leads and CEO."
        },
        {
            headerIndex: 2,
            info: "After my time at Tidy International, I decided to move onto National Hauora Coalition as a part of the " +
                "<a href='https://www.nhc.maori.nz/providers/information-for-general-practices/mohio/' target='_blank' rel='noopener noreferrer'>Mōhio team</a> where I improve multiple various products Mōhio has, such as: " +
                "Gen_2040 data tool, Mōhio Forms, Mōhio MVC, the practice data Statusboards and refactoring of the Statusboards. I have assisted in improving the user interface to reduce navigation time by removing critical issues " +
                "to ensure users can easily streamline their processess without any disruptions."
        },
        {
            headerIndex: 3,
            info: "I was working alongside another developer on a Poker Trainer for people to learn the chances of winning through multiple different card scenarios. Currently the minimum viable product only supports " +
            "3,4 and 5 card present on the table with your hand. You are presented with the opponents hands to compare against to make it easier for comarison. My contribution was to make a custom user interface " +
            "with minor assistance from the Material UI library with items such as: the slider. I also had contributions regarding assisting with the Rust backend via minor contributions and code reviews."
        }
    ],
    2023: [
        {
            headerIndex: 0,
            info: "I completed my Masters of Research (Science) in Computer Science through The University of Waikato. Thesis can be found "
                + "<a href='https://researchcommons.waikato.ac.nz/items/8c1bff18-8137-4296-8bbd-ddbd085be9cf' target='_blank' rel='noopener noreferrer'>here</a>"
        }
    ],
    2024: [
        {
            headerIndex: 0, info: "I managed to launch the domain Lightsorctechnologies.com using AWS products (Cloudfront, Route53, S3 bucket, certification and licenses) to develop and manage the website using a React frontend. " +
            "I am using a custom made interface alongside Material UI's Box component and React-fast-marquee for some components to assist with difficult designs." +
            "I created both low fiedelity and high fiedelities Figma prototypes."
        },

        {
            headerIndex: 1,
            info: "A major project I was assigned in National Hauora Coalition was to improve the <a href='https://www.mohio.nz/analytics' target='_blank' rel='noopener noreferrer'>Statusboard</a> product where I was tasked to create a React project " + 
            "using React from a prototype provided by the team lead and business analyst to improve the current statusboard project which was not user friendly into a highly responsive user friendly project. " +
            "I created a smooth streamline process of calling the project from our backend project to call for the data from the database and send back and update responsively. " +
            "The libraries used were Bootstrap, Chart.JS and Material UI (MUI)."
        }
    ],
    // Add more years as needed
    //Templates
    //<a href='' target='_blank' rel='noopener noreferrer'>
};

export function removeAllChildren() {
    const sidebarHeaderText = document.getElementById('siderbarHeaderText'); // Target the header text
    const sidebarTextMessage = document.getElementById('sidebarText'); // Target the body text

    if (sidebarHeaderText) {
        sidebarHeaderText.textContent = ''; // Clear the header text
    }

    if (sidebarTextMessage) {
        sidebarTextMessage.textContent = ''; // Clear the body text
    }
}

export const circleClickInformation = (year: string, headers: string) => {
    removeAllChildren();

    const headerStrings = headers.split('\n'); // Split headers into an array
    const yearInt = parseInt(year);
    const informationArray = yearInfoMap[yearInt] || [];
    const infoObjects: string[] = []; // Initialize as an empty array

    headerStrings.forEach((header, index) => {
        if (informationArray[index]) {
            informationArray[index].headerIndex = index; // Dynamically assign the header
        } else {
            informationArray.push({ headerIndex: index, info: '' }); // Add new object if not enough in the array
        }
        // Push the current informationArray object into infoObjects
        infoObjects.push(informationArray[index].info);
    });
    return infoObjects;
};

/* INFORMATION VARIABLES */
export const main: sidebarText = {
    subheader: 'Solutions made simple.',
    info: 'As a software engineer with roughly 3 years of experience, I excel in commercial and public not-for-profit full-stack development. I have worked on various projects which include: integrating API systems into current systems, ' +
        'maintaining legacy systems and transforming them into a modern codebase solution alongside creating new features within the system, and updating systems to improve customer needs while communicating to understand their requirements. ' +
        'For more information feel free to click on my timeline, it is interactive!' +
        '<p/> <h3 style="text-decoration: none; font-weight: normal;">Key Projects:</h3>' +
        '<h4>Poker Project</h4>' +
        'Creating a minimum viable product (mvp) for end users to determine probabilities of winning in any given poker hand. The current implementation includes predictions for 3, 4 and 5 card on the table scenarios.' +
        'I worked with another Software Engineer where my primary role was to create hi fiedelity prototypes using Figma, develop the graphical user interface (GUI) in React, assist in development with Rust and assist with configurations of Tauri.' +
        '<h4>Māori Twitter Corpus</h4>' +
        'This involved me using the Python programming language with the pandas, beautifulsoup4, GetOldTweets3 libraries and Te Hiku Media script. ' +
        'The GetOldTweets3 and beautifulsoup4 libraries were used to scrape and transform public tweets from Twitter into a CSV file. ' +
        'Once accomplished we used the Te Hiku Media script and pandas library to read the information, transform each tweet into a dictionary which was compared against the Te Hiku Media Script to determine the percentage of a tweet which was Māori. ' +
        'If determined to be over a certain percentage we deemed it as Māori or partially Māori but if below a threshold was determined to be not counted as Māori which were separated into 3 different dataframes and written into their ' +
        'respective CSV files.' +
        'Once accomplished, we were able to see the main key words used, significant contributors and any external reason why the tweets of tweet activity and transform it into data models ready to be used for training models </br>' +
        '<h4>Huri Whakatau</h4> ' +
        'My research throughout my honours year which lead to a full paid scholarship for my masters involved research into the indigenous population of New Zealand called \'Māori\', the goal of the project was to identify the most cutting-edge ' +
        'method to create software. Throughout my research I incorporated multiple pilot and user studies to determine the best quality approach alongside make appearances on ' +
        '<a href="https://www.engineeringnz.org/news-insights/embracing-m%C4%81tauranga-and-kaupapa-m%C4%81ori/" target="_blank" rel="noopener noreferrer"> Engineering New Zealand </a>' +
        'and a podcast called ' +
        '<a href="https://podcasters.spotify.com/pod/show/alyce-lysaght/episodes/14--Tamahau-Brown-e1fvkos" target="_blank" rel="noopener noreferrer"> Māori in Engineering </a>' +
        'For full details on my findings, my thesis can be found ' +
        '<a href="https://researchcommons.waikato.ac.nz/items/8c1bff18-8137-4296-8bbd-ddbd085be9cf" target="_blank" rel="noopener noreferrer"> here </a>'
};
export const skills: sidebarText = {
    subheader: 'Skills',
    info: '<ul>'+
    '<li/>FullStack Engineer '+
    '<li/>SQL/DAX '+
    '<li/>Data Engineering '+
    '<li/>Agile Development Processes'+
    '<li/>Kanban Processes '+
    '<li/>Research and statistics '+
    '<li/>Analysis '+
    '<li/><a href="https://researchcommons.waikato.ac.nz/items/8c1bff18-8137-4296-8bbd-ddbd085be9cf" target="_blank" rel="noopener noreferrer"> Requirements Engineering</a>'+
    '<li/>C<li/>'+
    'C# (<a href="https://www.testdome.com/certificates/faf8cb2eb9fe42968fdb78b004727de0" target="_blank" rel="noopener noreferrer">Testdome certified</a>)'+
    '<li/> Angular (<a href="https://www.sololearn.com/en/certificates/CC-F5YW5C6T" target="_blank" rel="noopener noreferrer">Sololearn certified</a>)'+
    '<li/>React'+
    '<li/> Python (<a href="https://www.testdome.com/certificates/ab6fbf16521a4a968ce457e39c83add4" target="_blank" rel="noopener noreferrer">Testdome certified</a>)<li/>'+
    'AWS(<a href="https://www.testdome.com/certificates/fd96d3205ff94b73ac5cd0787e7213ea" target="_blank" rel="noopener noreferrer">Testdome certified</a>)'+
    '<li/>Azure (<a href="https://www.testdome.com/certificates/79b57863735b42e58b9a036f967f3010" target="_blank" rel="noopener noreferrer">Testdome certified</a>)' +
    '</ul>'
};
export const education: sidebarText = {
    subheader: 'Education and experience',
    info: '<ul><li/>MSc (Research) Computer Science at the University of Waikato (2021-2023) <li/>BCMS (Hons) at the University of Waikato (2017-2020) <li/>MEA Mobile Ltd: Student Internship: July 2019 - October 2019 <li/>Nga Pae o Te Maramatanga: ' +
        'Student Internship (December 2019 - March 2020) <li/>Tidy International: Software Engineer (March 2022-September 2022) <li/>National Hauora Coalition: Software Developer (October 2022 - October 2024)</ul>'
};
export const contactDetails: sidebarText = {
    subheader: 'Contact information',
    info: 'Email: Tamahaub@outlook.co.nz \n Phone number: +64 22 421 3343'
};